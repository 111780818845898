import {
  ImageResourceModel,
} from '../../../shared/models/image-resource.model';
import dayjs, { Dayjs } from 'dayjs';
import { ArticleResponseModel, ShowImageOnApp } from './article-response.model';

export class ArticleModel {

  id: string;
  star: boolean;
  paywall: boolean;
  sponsored: boolean;
  name: string;
  author: string;
  shortDescription: string;
  description: string; // html string
  video = '';
  publishedAt: Dayjs | null;
  updatedAt: Dayjs | null;
  image: ImageResourceModel;
  categoryId: string;
  // custom attributes to improve code readability
  // does not exist in the API response
  hasImage = true;
  isMultiAuthors = false;
  shouldShowCoverImage: boolean;

  constructor(articleResponse: ArticleResponseModel) {
    this.id = articleResponse.id.toString();
    this.star = articleResponse.star;
    this.paywall = articleResponse.paywall;
    this.sponsored = articleResponse.sponsored;
    this.name = articleResponse.name;
    this.author = articleResponse.author;
    this.shortDescription = articleResponse.short_description;
    this._setVideo(articleResponse.video);
    this.publishedAt = dayjs(articleResponse.published_at);
    this.updatedAt = (!!articleResponse.updated_at)
                     ? dayjs(articleResponse.updated_at)
                     : null;
    this._setImage(articleResponse.image);
    this.categoryId = (articleResponse.category_id)
                      ? articleResponse.category_id.toString()
                      : '';
    this._setIsMultiAuthors(articleResponse.author);
    this._setShouldShowCoverImage(articleResponse.show_image_app);
    this._transformInDescriptionBrToParagraph(articleResponse.description);
  }

  private _setImage(image: ImageResourceModel | null) {

    if (!image) {
      this.hasImage = false;
      return;
    }

    if (!Object.keys(image).length) {
      this.hasImage = false;
      return;
    }

    this.image = image;
  }

  private _setVideo(originalUrl: string) {

    if (!originalUrl) {
      return;
    }

    // Gets youtube.com, youtu.be
    const isFromYoutube = originalUrl.includes('youtu');

    if (!isFromYoutube) {
      this.video = originalUrl;
      return;
    }

    if (originalUrl.includes('/embed/')) {
      this.video = originalUrl;
      return;
    }

    const videoUrl = new URL(originalUrl);

    this.video = `https://www.youtube.com/embed/${ videoUrl.pathname }`;
  }

  private _setIsMultiAuthors(author: string) {
    this.isMultiAuthors = author.includes(',')
                          || author.includes('/')
                          || author.includes(' e ');
  }

  private _setShouldShowCoverImage(show_image_app: ShowImageOnApp) {
    this.shouldShowCoverImage = show_image_app === '2';
  }

  private _transformInDescriptionBrToParagraph(description: string) {

    if (!description) {
      return
    }

    this.description = description.replace(/<br\s*\/?>/gi, '<p></p>');
  }
}
